<template>
  <b-card
    v-if="Object.keys(post).length"
    class="blog-edit-wrapper"
  >

    <!-- media -->
    <b-row>
      <b-col md="6">
        <!-- Post Preview -->
        <div style="margin: 20px; width: 300px; height: auto; border: 1px solid #ccc;">
          <tweet
            v-if="post.postType === 'tweet'"
            :post="post"
          />
          <news
            v-else-if="post.postType === 'news'"
            :post="post"
          />
          <youtube
            v-else-if="post.postType === 'youtube'"
            :post="post"
          />
          <advertisement
            v-else-if="post.postType === 'ad'"
            :post="post"
          />
          <post
            v-else
            :post="post"
          />
        </div>
        <!--/ Post Preview -->
      </b-col>

      <b-col md="6">
        <b-col>
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Author"
                class="mb-2"
              >
                <b-media
                  no-body
                  vertical-align="center"
                >
                  <b-media-aside class="mr-75">
                    <b-avatar
                      v-if="post.author === null || post.author === undefined"
                      size="38"
                    />
                    <b-avatar
                      v-else
                      size="38"
                      :src="post.author.avatar"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <h6
                      v-if="post.author === null || post.author === undefined"
                      class="mb-25"
                    >
                      None
                    </h6>
                    <h6
                      v-else
                      class="mb-25"
                    >
                      {{ post.author.name }}
                    </h6>
                    <b-card-text>{{ post.pubDate }}</b-card-text>
                  </b-media-body>

                </b-media>
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group
                label="Published"
                label-for="blog-edit-published"
                class="mb-2"
              >
                <div>
                  <b-form-checkbox
                    :checked="post.published"
                    class="custom-control-secondary"
                    name="check-button"
                    switch
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col xs="12">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Category"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="post.categories"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  taggable
                  push-tags
                  :options="categoryOption"
                  placeholder="Add Categories"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="Type"
                label-for="blog-edit-type"
                class="mb-2"
              >
                <v-select
                  id="blog-edit-type"
                  v-model="post.postType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOption"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col xs="12">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Team"
                label-for="blog-edit-team"
                class="mb-2"
              >
                <b-form-input
                  id="blog-edit-team"
                  v-model="post.team"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                label="City"
                label-for="blog-edit-city"
                class="mb-2"
              >
                <b-form-input
                  id="blog-edit-city"
                  v-model="post.city"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Keywords"
            label-for="blog-edit-keywords"
            class="mb-2"
          >
            <v-select
              id="blog-edit-keywords"
              v-model="post.keywords"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              taggable
              push-tags
              placeholder="Add Keywords"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Raw Keywords"
            label-for="blog-edit-rawkeywords"
            class="mb-2"
          >
            <v-select
              id="blog-edit-rawkeywords"
              v-model="post.rawkeywords"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              taggable
              push-tags
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Processing Text"
            label-for="blog-edit-processingText"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-processingText"
              v-model="post.processingText"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Tags"
            label-for="blog-edit-tags"
            class="mb-2"
          >
            <v-select
              id="blog-edit-tags"
              v-model="post.tags"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              taggable
              push-tags
              placeholder="Add Tags"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Label"
            label-for="blog-edit-label"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-label"
              v-model="post.label"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Post Status"
            label-for="blog-edit-label"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-post-status"
              v-model="post.postStatus"
            />
          </b-form-group>
        </b-col>
      </b-col>
    </b-row>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Title"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-textarea
              id="blog-edit-title"
              v-model="post.title"
              placeholder="Enter title..."
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Link"
            label-for="blog-edit-link"
            class="mb-2"
          >
            <b-form-textarea
              id="blog-edit-link"
              v-model="post.link"
              placeholder="https://..."
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Description"
            label-for="blog-content"
            class="mb-2"
          >
            <b-form-textarea
              id="textarea"
              v-model="post.description"
              placeholder="Enter something..."
              rows="5"
              max-rows="10"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Featured Image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="imageUrl"
                  height="auto"
                  width="290"
                  class="rounded mr-2 mb-1 mb-md-0"
                  @load="imageLoad"
                />
              </b-media-aside>

              <b-col style="100%">
                <b-form-group
                  label="Image Link"
                  label-for="blog-edit-image"
                >
                  {{ post.images[0] }}
                  <br>
                  {{ imageUrl }}
                  <b-form-textarea
                    id="blog-edit-image-link"
                    v-model="post.images[0]"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
                <b-form-group
                  label="Image Position"
                  label-for="blog-edit-imagepos"
                  class="mb-2"
                >
                  <v-select
                    id="blog-edit-imagepos"
                    v-model="post.imagePos"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    taggable
                    push-tags
                    :options="positionOption"
                    placeholder="Leave blank if no override"
                  />
                </b-form-group>
              </b-col>

            </b-media>
          </div>
        </b-col>

        <div>
          TBD/REMOVE: Feed: {{ post.source }}
        </div>

        <b-col
          cols="12"
          class="mt-50 text-center"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="savePostChanges"
          >
            Save Changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="goBack"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->

  </b-card>
</template>

<script>
import {
  ref, reactive, toRefs, computed,
} from '@vue/composition-api'

import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BButton, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { subject as an } from '@casl/ability'
import vSelect from 'vue-select'

import router from '@/router'

import Post from '../posts/Post.vue'
import Tweet from '../posts/Tweet.vue'
import Youtube from '../posts/Youtube.vue'
import News from '../posts/News.vue'
import Advertisement from '../posts/Advertisement.vue'

import * as PostService from '../../services/PostService'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    vSelect,

    // Post Types
    Post,
    Tweet,
    Youtube,
    News,
    Advertisement,
  },

  directives: {
    Ripple,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
  },

  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)
    const items = reactive({
      post: {},
      blogFile: null,
      // TBD/TODO - Change these to be defined in the general config file instead, or possibly in vuex?
      categoryOption: ['nfl', 'nba', 'mlb', 'nhl', 'ncaa'],
      positionOption: ['center center', 'center top', 'center bottom', 'left top', 'left center', 'left bottom', 'right top', 'right center', 'right bottom', '50% 50%'],
      typeOption: ['post', 'story', 'news', 'tweet', 'youtube', 'ad', 'podcast'],
    })
    const event = reactive({
      imageError: false,
      isImageLoaded: false,
    })

    // console.log('\n\n\n\n\n\n\n\n=============================================>')
    // console.log(router.currentRoute.params.id)

    PostService.getPostByIdWithLock(router.currentRoute.params.id)
    // PostService.getPostById(router.currentRoute.params.id)
      .then(response => {
        // items.post = response.data.data.data
        items.post = an('Post', response.data.data.data)
      })
      // .catch(error => {
      //   // console.log('\n---------------ERROR-----------------------------------------------')
      //   // console.log(error)
      // })

    async function savePostChanges() {
      await PostService.updatePost(items.post)
      router.go(-1)
    }

    function goBack() {
      router.go(-1)
    }

    function imageLoadError() {
      // console.log(`[PostEdit] Image failed to load: ${items.post.images[0]}`)
      // if (items.post.images[0] !== '') {
      //   event.imageError = true
      // }
      // // props.post.images[0] = '' // eslint-disable-line no-param-reassign
      // // evt.target.src = '' // eslint-disable-line no-param-reassign
      if (items.post.images[0] !== '') {
        event.isImageLoaded = false
      }
    }
    function imageLoad() {
      // console.log(`[PostEdit] Image DID load: ${items.post.images[0]}`)
      event.imageError = false
      event.isImageLoaded = true
    }
    // const imageUrl = computed(() => {
    //   if (event.imageError) {
    //     return 'https://fashionindustrybroadcast.com/wp-content/uploads/2016/08/Screen-Shot-2016-08-16-at-5.20.46-PM-1187x800.png'
    //   }
    //   return items.post.images[0]
    // })

    const imageUrl = computed(() => {
      if (event.isImageLoaded) {
        return items.post.images[0]
      }
      // items.post.images[0] = 'https://fashionindustrybroadcast.com/wp-content/uploads/2016/08/Screen-Shot-2016-08-16-at-5.20.46-PM-1187x800.png'
      return 'https://fashionindustrybroadcast.com/wp-content/uploads/2016/08/Screen-Shot-2016-08-16-at-5.20.46-PM-1187x800.png'
    })

    return {
      ...toRefs(items),
      refInputEl,
      refPreviewEl,
      savePostChanges,
      goBack,
      imageLoad,
      imageUrl,
      imageLoadError,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
